import { ErrorHandler } from '@apollo/link-error'

import { store } from '@/redux/store'

const errorLogger: ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError)
    // eslint-disable-next-line no-console
    console.log(
      `[Network error]: ${networkError}. Backend is unreachable. Is it running?`
    )
}

enum AppSyncAuthentiactionType {
  OPENID_CONNECT = 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
}

// AppSync config
const appSyncUrl = process.env.NEXT_PUBLIC_NOTIFICATIONS_URL
const appSyncRegion = 'us-west-2'
const appSyncAuth = {
  type: AppSyncAuthentiactionType.OPENID_CONNECT,
  jwtToken: async () => store.getState().token.token,
}

export { errorLogger, appSyncUrl, appSyncRegion, appSyncAuth }
