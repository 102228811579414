import { Global, css } from '@emotion/react'

const styles = css`
  body {
    background-color: #f0f5fd;
  }
  html {
    overflow: hidden;
  }
`

export const GlobalStyles = () => <Global styles={styles} />
