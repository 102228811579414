import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

const StyledTextarea = styled(ChakraTextarea)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.47px;
  line-height: 21px;
  color: #2d2e41;

  ::placeholder {
    font-weight: 300;
    color: #2d2e41;
    opacity: 30%;
    font-style: italic;
  }
`

export interface TextareaIProps extends TextareaProps {
  /**
   * Textarea id.
   */
  id?: string
  /**
   * Textarea name.
   */
  name?: string
  /**
   * If `true`, the Textarea will display valid state styles with check icon.
   */
  valid?: boolean
  /**
   * If `true`, the Textarea will display error state styles.
   */
  isInvalid?: boolean
  /**
   * The placeholder value for this Textarea.
   */
  placeholder?: string
  /**
   * The width of the Textarea element.
   */
  width?: string
  [key: string]: unknown
}

export const Textarea = ({
  name,
  id,
  isInvalid,
  placeholder,
  valid,
  textareaRef,
  width = '100%',
  ...rest
}: TextareaIProps) => {
  return (
    <StyledTextarea
      autoCapitalize='off'
      autoComplete='off'
      autoCorrect='off'
      bgColor='white'
      borderColor={valid ? '#2BD2B4' : '#9FB8E5'}
      borderWidth='2px'
      data-testid='text-Textarea'
      errorBorderColor='#D01030'
      isInvalid={isInvalid}
      name={name || id}
      placeholder={placeholder}
      ref={textareaRef as React.RefObject<HTMLTextAreaElement>}
      spellCheck='false'
      variant='outline'
      width={width}
      {...rest}
    />
  )
}
